




























import { VesselDocumentModel } from "@/api/generated";
import { Vue, Component, Prop } from "vue-property-decorator";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import VesselDocumentTable from "@/components/vessel/VesselDocumentTable.vue";
import VesselDocumentCreateDialog from "@/components/dialogs/VesselDocumentCreateDialog.vue";
import VesselDocumentUpdateDialog from "@/components/dialogs/VesselDocumentUpdateDialog.vue";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    VesselDocumentTable,
    VesselDocumentCreateDialog,
    VesselDocumentUpdateDialog
  }
})
export default class VesselDocuments extends Vue {
  @Prop(String) private vesselId!: string;
  private selectedVesselDocumentId: string | undefined = "";
  private refreshKey = false;
  private createDialog = false;
  private updateDialog = false;

  private getDocuments() {
    this.refreshKey = !this.refreshKey;
  }

  private onCreateDocument() {
    this.createDialog = true;
  }

  private onEditDocument(document: VesselDocumentModel) {
    if (!document.vesselId) {
      return;
    }

    this.selectedVesselDocumentId = document.vesselDocumentId;
    this.updateDialog = true;
  }

  private async onDownloadDocument(document: VesselDocumentModel) {
    if (!document.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      document.documentId
    );

    FileDownloadUtility.DownloadFile(fileResponse.data, document.filename);
  }

  private async onDeleteDocument(document: VesselDocumentModel) {
    if (!document.vesselDocumentId) {
      return;
    }

    try {
      await Api.VesselDocumentService.apiVesseldocumentVesselDocumentIdDelete(
        document.vesselDocumentId
      );
      snackbarModule.setSnackbarMessage("Document deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete document");
    }
  }

  private async onArchiveDocument(document: VesselDocumentModel) {
    if (!document.vesselDocumentId) {
      return;
    }

    try {
      await Api.VesselDocumentService.apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut(
        document.vesselDocumentId,
        true
      );
      snackbarModule.setSnackbarMessage("Document Archived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to archive document");
    }
    this.getDocuments();
  }

  private async onUnarchiveDocument(document: VesselDocumentModel) {
    if (!document.vesselDocumentId) {
      return;
    }

    try {
      await Api.VesselDocumentService.apiVesseldocumentArchiveVesselDocumentIdArchiveStatusPut(
        document.vesselDocumentId,
        false
      );
      snackbarModule.setSnackbarMessage("Document Unarchived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to unarchive document");
    }
    this.getDocuments();
  }
}
